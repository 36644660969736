import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

export const useBillings = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(BILLINGS_QUERY, variables, () => ({
    enabled:
      !!variables.value.businessId &&
      (!!variables.value.supplierId || !!variables.value.fromDate || !!variables.value.toDate),
  }));
  const billings = computed(() => result.value?.billings?.nodes ?? []);

  onError((err) => {
    console.error('useBillings', err);
    error();
  });

  return { billings, loading };
};

const BILLINGS_QUERY = gql`
  query billings($businessId: ID!, $supplierId: ID, $fromDate: ISODate, $toDate: ISODate) {
    billings(businessId: $businessId, supplierId: $supplierId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        id
        date
        totalAmount
        type
        eventReferences {
          document {
            id
            type
            documentNumber
          }
        }
      }
    }
  }
`;
