import { useQuery, useResult } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import { computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

const PAYMENTS_QUERY = gql`
  query payments($businessId: ID!, $supplierId: ID, $fromDate: ISODate, $toDate: ISODate) {
    payments(businessId: $businessId, supplierId: $supplierId, fromDate: $fromDate, toDate: $toDate) {
      totalCount
      nodes {
        id
        date
        requestedDate
        amount
        isRefund
        eventReferences {
          documentId
          reference
        }
      }
    }
  }
`;

export const usePayments = (variables) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(PAYMENTS_QUERY, variables, () => ({
    enabled:
      !!variables.value.businessId &&
      (!!variables.value.supplierId || !!variables.value.fromDate || !!variables.value.toDate),
  }));
  const paymentConnection = useResult(result, { nodes: [], totalCount: 0 });

  const payments = computed(() => paymentConnection.value.nodes);
  const totalCount = computed(() => paymentConnection.value.totalCount);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('usePayments', err);
    error();
  });

  return { payments, totalCount, loading, refetch };
};
