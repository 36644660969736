<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-5">
      <div>
        <h3 v-if="showTitle">
          {{ $t('payment.reconciliationStatementReport.tabs.restaurantPerspective', { restaurant: restaurant.name }) }}
        </h3>
      </div>
      <div class="d-flex align-items-center">
        <p class="mx-2">{{ $t('payment.reconciliationStatementReport.dateRange') }}</p>
        <el-date-picker
          v-model="dateRange"
          :clearable="false"
          value-format="yyyy-MM-dd"
          size="small"
          type="daterange"
          :format="$direction === 'ltr' ? 'MM.dd.yyyy' : 'dd.MM.yyyy'"
          :placeholder="`${$t('commons.select')}...`"
        />
      </div>
    </div>
    <div v-loading="loading" class="rounded border overflow-hidden">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="header-index" />
            <th v-for="(column, columnIndex) in columns" :key="`${columnIndex}-${column.key}`" scope="col">
              <p class="fw-bold">{{ column.header }}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :colspan="columns.length + 1" class="bg-secondary text-center">
              {{
                $t('payment.reconciliationStatementReport.table.openingBalance', {
                  date: formatDate(openingBalanceDate),
                  amount: formatMoney(openingBalance),
                })
              }}
            </td>
          </tr>
          <template v-for="(rowData, rowIndex) in tableData">
            <tr :key="rowIndex">
              <td class="cell-index" width="55">
                {{ rowIndex + 1 + pageSize * currentPageIndex }}
              </td>
              <td v-for="{ key } in columns" :key="`${rowIndex}-${key}`" width="17%">
                {{ rowData[key] }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="eventsTotalCount > pageSize"
        layout="prev, pager, next, jumper"
        small
        background
        :total="eventsTotalCount"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import { ref, computed } from 'vue';
import { DateTime } from 'luxon';

import { useCurrency } from '@/modules/core/compositions/money-currency';

import { usePayments, useBillings } from './compositions';
import { usePayables } from '../../../compositions';
import { formatDate } from '../../../tools/formatters';

const TABLE_HEADER = {
  DATE: 'date',
  EVENT_TYPE: 'eventType',
  REFERENCE: 'reference',
  DEBIT: 'debit',
  CREDIT: 'credit',
  BALANCE: 'balance',
};

export default {
  props: {
    restaurant: { type: Object, required: true },
    supplier: { type: Object, required: true },
    showTitle: { type: Boolean, default: true },
  },
  setup(props) {
    const dateRange = ref([
      DateTime.local().minus({ months: 2 }).startOf('month').toISODate(),
      DateTime.local().toISODate(),
    ]);

    const {
      payments,
      totalCount: paymentTotalCount,
      loading: paymentsLoading,
    } = usePayments(
      computed(() => ({
        businessId: props.restaurant.id,
        supplierId: props.supplier.id,
        fromDate: dateRange.value[0],
        toDate: dateRange.value[1],
      }))
    );
    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        businessId: props.restaurant.id,
        supplierId: props.supplier.id,
        fromDate: dateRange.value[0],
        toDate: dateRange.value[1],
      }))
    );

    const options = computed(() => ({ enabled: !!props.restaurant.id }));

    const { payables, loading: balanceLoading } = usePayables(
      computed(() => ({
        businessId: props.restaurant.id,
        supplierId: props.supplier.id,
        toDate: DateTime.fromISO(dateRange.value[0]).minus({ days: 1 }).toISODate(),
      })),
      options
    );

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      activeTab: ref(0),
      dateRange,
      payments,
      billings,
      openingBalance: computed(() => payables.value[0]?.balance || 0),
      loading: computed(() => paymentsLoading.value || billingsLoading.value || balanceLoading.value),
      pageSize: 50,
      currentPageIndex: ref(0),
      formatDate,
      formatMoney,
      eventsTotalCount: computed(() => billings.value.length + paymentTotalCount.value),
    };
  },
  computed: {
    openingBalanceDate() {
      return DateTime.fromISO(this.dateRange[0]).minus({ days: 1 }).toISODate();
    },
    columns() {
      return [
        {
          header: this.$t('payment.reconciliationStatementReport.table.date'),
          key: TABLE_HEADER.DATE,
        },
        {
          header: this.$t('payment.reconciliationStatementReport.table.eventType'),
          key: TABLE_HEADER.EVENT_TYPE,
        },
        {
          header: this.$t('payment.reconciliationStatementReport.table.reference'),
          key: TABLE_HEADER.REFERENCE,
        },
        {
          header: this.$t('payment.reconciliationStatementReport.table.debit'),
          key: TABLE_HEADER.DEBIT,
        },
        {
          header: this.$t('payment.reconciliationStatementReport.table.credit'),
          key: TABLE_HEADER.CREDIT,
        },
        {
          header: this.$t('payment.reconciliationStatementReport.table.balance'),
          key: TABLE_HEADER.BALANCE,
        },
      ];
    },
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
    tableData() {
      if (this.loading) return [];
      const convertedBillings = this.billings.map((b) => ({
        [TABLE_HEADER.DATE]: new Date(b.date),
        [TABLE_HEADER.EVENT_TYPE]:
          b.type === 'balance'
            ? this.$t('payment.reconciliationStatementReport.balanceBilling')
            : this.$t(`document.exports.schema.type.shortName.${b.eventReferences[0].document.type}`),
        [TABLE_HEADER.REFERENCE]: b.eventReferences[0].document ? b.eventReferences[0].document.documentNumber : '-',
        [TABLE_HEADER.DEBIT]: '-',
        [TABLE_HEADER.CREDIT]: this.formatMoney(b.totalAmount / 100),
        [TABLE_HEADER.BALANCE]: b.totalAmount / 100,
      }));

      const convertedPayments = this.payments.map((p) => ({
        [TABLE_HEADER.DATE]: new Date(p.date || p.requestedDate),
        [TABLE_HEADER.EVENT_TYPE]: this.$t('payment.reconciliationStatementReport.table.payment'),
        [TABLE_HEADER.REFERENCE]: p.eventReferences[0]?.reference,
        [TABLE_HEADER.DEBIT]: this.formatMoney(p.amount * (p.isRefund ? -1 : 1)),
        [TABLE_HEADER.CREDIT]: '-',
        [TABLE_HEADER.BALANCE]: p.amount * -1,
      }));

      const data = convertedBillings
        .concat(convertedPayments)
        .sort((a, b) => a.date - b.date)
        .reduce((events, currEvent, index) => {
          const previousBalance = index === 0 ? this.openingBalance : events[events.length - 1].balance;

          events.push({
            ...currEvent,
            [TABLE_HEADER.DATE]: formatDate(currEvent.date),
            [TABLE_HEADER.BALANCE]: currEvent.balance + previousBalance,
          });
          return events;
        }, []);

      return data
        .map((d) => ({ ...d, [TABLE_HEADER.BALANCE]: this.formatMoney(d[TABLE_HEADER.BALANCE]) }))
        .slice(this.currentPageIndex * this.pageSize, this.currentPageIndex * this.pageSize + this.pageSize);
    },
  },
};
</script>

<style scoped lang="scss">
td.cell-index,
th.header-index {
  [dir='ltr'] & {
    padding-right: 5px;
  }
  [dir='rtl'] & {
    padding-left: 5px;
  }
}

tr:last-child > td {
  border-bottom-width: 0px;
}
</style>
