<template>
  <div class="overflow-scroll p-1">
    <div class="d-flex flex-column gap-5">
      <Table
        v-for="[instructionNumber, payments] in Object.entries(paymentGroups)"
        :key="instructionNumber"
        :columns="columns"
        :data="transformToTableData(instructionNumber, payments)"
        :cell-class="(rowIndex) => getCellStyle(instructionNumber, rowIndex)"
        :hover="!disabled"
        border
        rounded
        :class="{ ['table-focus-shadow border-primary']: showOutlineSelected }"
        custom-class="payments-table"
        @row-click="handleRowClick(instructionNumber, $event)"
      >
        <template #cell-date="{ rowData: { date } }">
          <p>{{ formatDate(date) }}</p>
        </template>
        <template #cell-amount="{ rowData: { amount } }">
          <p>{{ formatMoney(amount) }}</p>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { Table } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../../../tools/formatters';

const TABLE_HEADER = {
  SUPPLIER: 'supplier',
  DATE: 'date',
  SUPPLIER_BANK_ACCOUNT: 'creditedBankAccount',
  AMOUNT: 'amount',
  INSTRUCTION_NUMBER: 'instructionNumber',
  REFERENCE: 'reference',
};

export default {
  components: { Table },
  props: {
    paymentGroups: { type: Object, required: true },
    suppliers: { type: Array, required: true },
    matchedMap: { type: Object, required: true },
    selectedPaymentIndicator: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    showOutlineSelected: { type: Boolean, default: false },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return { formatMoney };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translate('supplier'),
          key: TABLE_HEADER.SUPPLIER,
          width: '147px',
        },
        {
          header: this.translate('date'),
          key: TABLE_HEADER.DATE,
          width: '100px',
        },
        {
          header: this.translate('creditedBankAccount'),
          key: TABLE_HEADER.SUPPLIER_BANK_ACCOUNT,
          width: '135px',
        },
        {
          header: this.translate('amount'),
          key: TABLE_HEADER.AMOUNT,
          width: '130px',
        },
        {
          header: this.translate('instructionNumber'),
          key: TABLE_HEADER.INSTRUCTION_NUMBER,
          width: '100px',
        },
        {
          header: this.translate('reference'),
          key: TABLE_HEADER.REFERENCE,
          width: '100px',
        },
      ];
    },
    suppliersById() {
      return this.suppliers.reduce((acc, s) => ({ ...acc, [s.id]: s }), {});
    },
  },
  methods: {
    formatDate,
    translate(key) {
      return this.$t(`payment.paymentMatchingModal.paymentsToMatch.table.${key}`);
    },
    transformToTableData(instructionNumber, payments) {
      return payments.map((p, index) => {
        if (index === 0 && p.id.includes(instructionNumber)) {
          return {
            id: p.id,
            [TABLE_HEADER.SUPPLIER]: this.$t('payment.paymentMatchingModal.paymentsToMatch.suppliers', {
              count: p.suppliers,
            }),
            [TABLE_HEADER.DATE]: p.date,
            [TABLE_HEADER.SUPPLIER_BANK_ACCOUNT]: this.$t('payment.paymentMatchingModal.paymentsToMatch.billings', {
              count: p.accountNumbers,
            }),
            [TABLE_HEADER.AMOUNT]: p.amount,
            [TABLE_HEADER.INSTRUCTION_NUMBER]: p.instructionNumber,
            [TABLE_HEADER.REFERENCE]: this.getReference(instructionNumber, index, p.id),
            hover:
              !this.isGroupHaveSelected(instructionNumber) ||
              (this.isGroupHaveSelected(instructionNumber) &&
                this.isAllGroupSelected(instructionNumber, payments[0].id)),
          };
        }

        return {
          id: p.id,
          [TABLE_HEADER.SUPPLIER]: this.suppliersById[p.supplierId]?.name ?? '-',
          [TABLE_HEADER.DATE]: p.date,
          [TABLE_HEADER.SUPPLIER_BANK_ACCOUNT]: p.transactions[0].destinationBankAccount?.accountNumber,
          [TABLE_HEADER.AMOUNT]: p.amount,
          [TABLE_HEADER.INSTRUCTION_NUMBER]: p.transactions[0].instructionNumber,
          [TABLE_HEADER.REFERENCE]: this.getReference(instructionNumber, index, p.id, payments[0].id),
          hover: !this.isAllGroupSelected(instructionNumber, payments[0].id),
        };
      });
    },
    getReference(instructionNumber, index, rowId, groupId) {
      const singleRowId = this.generateMatchMapKey(instructionNumber, index, rowId);
      const matchedById = this.matchedMap[singleRowId];
      if (matchedById) return matchedById.reference;

      if (groupId) {
        const tableGroupId = this.generateMatchMapKey(instructionNumber, 0, groupId);
        const matchedByGroup = this.matchedMap[tableGroupId];
        if (matchedByGroup) return matchedByGroup.reference;
      }

      return '-';
    },
    isAllGroupSelected(instructionNumber, groupId) {
      const groupKey = this.generateMatchMapKey(instructionNumber, 0, groupId);
      return !!this.matchedMap[groupKey];
    },
    isGroupHaveSelected(instructionNumber) {
      const keys = Object.keys(this.matchedMap).filter((key) => key.split('-')[0] === instructionNumber);
      return !!keys.length;
    },
    getCellStyle(instructionNumber, rowIndex) {
      const styles = [];
      if (
        this.selectedPaymentIndicator?.includes(this.paymentGroups[instructionNumber][rowIndex].id) ||
        this.selectedPaymentIndicator?.includes(this.paymentGroups[instructionNumber][0].id)
      )
        styles.push('bg-selected');

      if (this.paymentGroups[instructionNumber][rowIndex].id.includes(instructionNumber)) {
        styles.push('summary-row');
        if (!this.selectedPaymentIndicator?.includes(this.paymentGroups[instructionNumber][0].id))
          styles.push('bg-secondary');
      }

      return styles.join(' ');
    },
    handleRowClick(instructionNumber, rowIndex) {
      const clickedIndicator = this.generateMatchMapKey(
        instructionNumber,
        rowIndex,
        this.paymentGroups[instructionNumber][rowIndex].id
      );
      this.$emit(
        'update:selectedPaymentIndicator',
        this.selectedPaymentIndicator !== clickedIndicator ? clickedIndicator : null
      );
    },
    generateMatchMapKey(instructionNumber, rowIndex, rowId) {
      return `${instructionNumber}-${rowIndex}-${rowId}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.table-focus-shadow {
  box-shadow: 0px 0px 0px 4px $surfaces-selected;
}

::v-deep .payments-table {
  tr:not(.no-hover):hover td.bg-selected {
    background-color: change-color($color: $surfaces-selected, $lightness: 90%) !important;
    --bs-table-hover-bg: none;
  }
  .summary-row p {
    font-weight: $font-weight-bold !important;
  }
}
</style>
