import { render, staticRenderFns } from "./RestaurantPerspective.vue?vue&type=template&id=f6c74a72&scoped=true"
import script from "./RestaurantPerspective.vue?vue&type=script&lang=js"
export * from "./RestaurantPerspective.vue?vue&type=script&lang=js"
import style0 from "./RestaurantPerspective.vue?vue&type=style&index=0&id=f6c74a72&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6c74a72",
  null
  
)

export default component.exports